// extracted by mini-css-extract-plugin
export var CenterWrap = "NewsMedia-module--CenterWrap--b8238";
export var Container = "NewsMedia-module--Container--c6143";
export var FlexWrap = "NewsMedia-module--FlexWrap--5a025";
export var GridCenter = "NewsMedia-module--GridCenter--8d5d7";
export var NewsMedia = "NewsMedia-module--NewsMedia--df626";
export var btn = "NewsMedia-module--btn--8bf09";
export var btn_black = "NewsMedia-module--btn_black--9a328";
export var btn_purple = "NewsMedia-module--btn_purple--abc75";
export var item = "NewsMedia-module--item--423f5";
export var navContainer = "NewsMedia-module--navContainer--8ac4f";
export var next = "NewsMedia-module--next--e0568";
export var numbers = "NewsMedia-module--numbers--55558";
export var prev = "NewsMedia-module--prev--05795";
export var scroll = "NewsMedia-module--scroll--04c84";