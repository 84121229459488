import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './AlternatingStat.module.scss';

export const AlternatingStat = ({ slice }) => {
  return (
    <section className={sty.Alternating}>
      <Container>
        <div className={sty.CenterWrap}>
          <h2>{slice.primary.header}</h2>
        </div>
        {slice.items.map((item, index) => (
          <div className={`${sty.FlexWrap} ${sty["nth_"+index % 3]} ${sty["side_"+index % 2]}`} key={`stat: ${index}`}>
            {index % 2 === 0? 
              (
                <>
                  <div className={sty.stat}>
                    <PrismicRichText field={item.rt?.richText}/>
                  </div>
                  <div className={sty.imageBox}>
                    <GatsbyImage
                      image={item.img?.gatsbyImageData}
                      alt={item.img?.alt}
                      className={sty.image}
                    />
                  </div>
                </>
              ) :
              (
                <>
                  <div className={sty.imageBox}>
                    <GatsbyImage
                      image={item.img?.gatsbyImageData}
                      alt={item.img?.alt}
                      className={sty.image}
                    />
                  </div>
                  <div className={sty.stat}>
                    <PrismicRichText field={item.rt?.richText}/>
                  </div>
                </>
              )
            }    
          </div>
        ))}
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyAlternatingStat on PrismicHomepageDataBodyAlternatingStat {
    id
    primary {
      header 
    }
    items {
      img {
        gatsbyImageData(width: 573)
        alt 
      }
      rt {
        richText
      }
    }
  }
`
