// extracted by mini-css-extract-plugin
export var CenterWrap = "Partners-module--CenterWrap--9422c";
export var Container = "Partners-module--Container--89b9c";
export var FlexWrap = "Partners-module--FlexWrap--d09cb";
export var GridCenter = "Partners-module--GridCenter--68df1";
export var Partners = "Partners-module--Partners--1d9b9";
export var btn = "Partners-module--btn--7244d";
export var btn_black = "Partners-module--btn_black--148fd";
export var btn_purple = "Partners-module--btn_purple--cdd65";
export var copyBox = "Partners-module--copyBox--c0e7d";
export var imageBox = "Partners-module--imageBox--ca83b";
export var lightBox = "Partners-module--lightBox--fdc47";