import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { BottomMenu } from './BottomMenu'

export const Layout = ({ children, menu, activeDocMeta }) => {
  const querySiteData = useStaticQuery(graphql`
    query siteQuery {
      allPrismicSeo{
        nodes {
          data {
            site_title
            site_description
          }
          lang
        }
      }
      site {
        siteMetadata {
          title
          description
        }
      }  
    }
  `)
  const queryData = querySiteData.allPrismicSeo.nodes.find(site => site.lang === activeDocMeta.lang);
  const backupData = querySiteData.site.siteMetadata;

  return (
    <>
      <Helmet

       htmlAttributes={{
          lang: activeDocMeta.lang
        }}
       
      >
        <meta charSet="utf-8" />
        <title>{queryData?.data?.site_title || backupData.title}</title>
        <meta
          name="description"
          content={queryData?.data?.site_description || backupData.description}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&family=Poppins:wght@400;700;900&display=swap" rel="stylesheet"/>
      </Helmet>
      <TopMenu menu={menu} activeDocMeta={activeDocMeta} />
      <main id="main">{children}</main>
      <BottomMenu menu={menu}/>
    </>
  )
}
