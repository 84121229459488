// extracted by mini-css-extract-plugin
export var CenterWrap = "Map-module--CenterWrap--89a5a";
export var Container = "Map-module--Container--74576";
export var CopyBox = "Map-module--CopyBox--8d034";
export var FlexWrap = "Map-module--FlexWrap--90d09";
export var GridCenter = "Map-module--GridCenter--43def";
export var InfoWindow = "Map-module--InfoWindow--a4458";
export var btn = "Map-module--btn--f772e";
export var btn_black = "Map-module--btn_black--2e25a";
export var btn_purple = "Map-module--btn_purple--33c8f";
export var image = "Map-module--image--a12f7";