// extracted by mini-css-extract-plugin
export var CenterWrap = "AlternatingStat-module--CenterWrap--d27f3";
export var Container = "AlternatingStat-module--Container--61245";
export var FlexWrap = "AlternatingStat-module--FlexWrap--b6edf";
export var GridCenter = "AlternatingStat-module--GridCenter--67e4b";
export var btn = "AlternatingStat-module--btn--10905";
export var btn_black = "AlternatingStat-module--btn_black--92af2";
export var btn_purple = "AlternatingStat-module--btn_purple--c73c4";
export var image = "AlternatingStat-module--image--1640f";
export var imageBox = "AlternatingStat-module--imageBox--d33c2";
export var nth_0 = "AlternatingStat-module--nth_0--24f63";
export var nth_1 = "AlternatingStat-module--nth_1--b183b";
export var nth_2 = "AlternatingStat-module--nth_2--7470e";
export var side_1 = "AlternatingStat-module--side_1--36a56";
export var stat = "AlternatingStat-module--stat--87d54";
export var statBox = "AlternatingStat-module--statBox--d02cc";