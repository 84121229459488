// extracted by mini-css-extract-plugin
export var CenterWrap = "Homepage-module--CenterWrap--d23b4";
export var Container = "Homepage-module--Container--5c437";
export var FlexWrap = "Homepage-module--FlexWrap--967c2";
export var GridCenter = "Homepage-module--GridCenter--66f94";
export var H1 = "Homepage-module--H1--dc9fc";
export var H2 = "Homepage-module--H2--736ce";
export var H3 = "Homepage-module--H3--69081";
export var H4 = "Homepage-module--H4--89eef";
export var H5 = "Homepage-module--H5--40920";
export var H6 = "Homepage-module--H6--17286";
export var HomepageBg = "Homepage-module--HomepageBg--37587";
export var actionPages = "Homepage-module--actionPages--8b09e";
export var btn = "Homepage-module--btn--3cafc";
export var btn_black = "Homepage-module--btn_black--36b97";
export var btn_purple = "Homepage-module--btn_purple--5cbc8";
export var gatsbyFocusWrapper = "Homepage-module--gatsby-focus-wrapper--bab84";
export var subPages = "Homepage-module--subPages--c8508";
export var subheading = "Homepage-module--subheading--dac8a";