// extracted by mini-css-extract-plugin
export var CenterWrap = "TextImage-module--CenterWrap--01722";
export var Container = "TextImage-module--Container--4fb01";
export var FlexWrap = "TextImage-module--FlexWrap--f6259";
export var GridCenter = "TextImage-module--GridCenter--32cea";
export var TextImage = "TextImage-module--TextImage--a6bae";
export var btn = "TextImage-module--btn--243eb";
export var btn_black = "TextImage-module--btn_black--b3ea0";
export var btn_purple = "TextImage-module--btn_purple--ab00b";
export var copyBox = "TextImage-module--copyBox--f9ce5";
export var imageBox = "TextImage-module--imageBox--3aaf2";
export var imageRight = "TextImage-module--imageRight--daae1";
export var imageTag = "TextImage-module--imageTag--4ecdd";
export var nth_0 = "TextImage-module--nth_0--5a9e2";
export var nth_1 = "TextImage-module--nth_1--81948";
export var nth_2 = "TextImage-module--nth_2--2cfed";
export var nth_3 = "TextImage-module--nth_3--80b78";
export var paper_word = "TextImage-module--paper_word--7eba2";