/**
 * @param {string} email
 * @returns {string}
 * */
export function linkToApp(email) {
  const isDev = process.env.NODE_ENV === 'development'

  /** @type {string} */
  let base
  if (isDev) {
    base = 'http://localhost:3000/'
  } else {
    base = 'https://app.commit2act.org/'
  }

  if (email) {
    const p = new URLSearchParams({
      email,
    })
    base += '?' + p.toString()
  }
  return base
}
