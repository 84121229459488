// extracted by mini-css-extract-plugin
export var CenterWrap = "CentreAlignedColumnsWPaper-module--CenterWrap--bdfb7";
export var CentreAlignedColumnsWPaper = "CentreAlignedColumnsWPaper-module--CentreAlignedColumnsWPaper--b176f";
export var Container = "CentreAlignedColumnsWPaper-module--Container--2d746";
export var FlexWrap = "CentreAlignedColumnsWPaper-module--FlexWrap--ef5d2";
export var GridCenter = "CentreAlignedColumnsWPaper-module--GridCenter--617ca";
export var btn = "CentreAlignedColumnsWPaper-module--btn--29a35";
export var btn_black = "CentreAlignedColumnsWPaper-module--btn_black--e8595";
export var btn_purple = "CentreAlignedColumnsWPaper-module--btn_purple--47e2e";
export var imageBox = "CentreAlignedColumnsWPaper-module--imageBox--91d20";
export var item = "CentreAlignedColumnsWPaper-module--item--0d7bf";