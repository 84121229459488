import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"

import * as sty from './Leaders.module.scss';

export const Leaders = ({ slice, context }) => {
  const [leader, setLeader] = React.useState(0);
  
  
  return (
    <section className={sty.Leaders} id="Leaders" ref={context.ref}>
      <Container>
        <div className={sty.FlexWrap}>
          <div className={sty.copyBox}>
            <PrismicRichText field={slice.primary.header_rt?.richText}/>
            <div className={sty.leaderInfo}>
              <PrismicRichText field={slice.items[leader]?.rt?.richText}/>
            </div>
          </div>
          <div className={sty.leaderBox}>
            <div className={sty.leaderImage}>
              <GatsbyImage
                image={slice.items[leader].img?.gatsbyImageData}
                alt={slice.items[leader].img?.alt || ""}
                className={sty.image}
              />
            </div>
            <div className={sty.leaderIndex}>
              {slice.items.map((item,index) => (
                <button 
                  className={index == leader ? sty.active : sty.inactive}
                  key={`leader: ${index}`}
                  onClick={() => setLeader(index)}
                  value={index}
                >
                  <span>{index+1}</span>
                </button>
              ))}
            </div>
            
          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyLeaders on PrismicHomepageDataBodyLeaders {
    id
    primary {
      header_rt {
        richText
      }
    }
    items {
      img {
        gatsbyImageData
        alt 
      }
      rt {
        richText
      }
    }
  }
`
