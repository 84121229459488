import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Container } from './Container'
import { LanguageSwitcher } from './LanguageSwitcher'
import * as s from './TopMenu.module.scss'
export const TopMenu = ({ menu, activeDocMeta }) => {
  return (
    <header className={s.header}>
      <a className={s.skip_button} href="#main">   
        {menu.skip_to_content}
      </a>
      <LanguageSwitcher activeDocMeta={activeDocMeta} />
      <div className={s.FlexWrap}>
        <PrismicLink href={menu.homepage?.url} className={s.logoWrap}>
          <GatsbyImage
            image={menu.header_logo?.gatsbyImageData}
            alt={menu.header_logo?.alt || ""}
            className={s.logo}
          />
        </PrismicLink>
        <PrismicLink href={menu.button_link?.url} className={s.ButtonLink}>
          <span>
            {menu.button_label}
          </span>  
        </PrismicLink>
      </div>
    </header>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      skip_to_content
      homepage{
        url
      }
      header_logo {
        gatsbyImageData(height: 130)
        alt 
      }
      button_label
      button_link {
        url
      }
    }
  }
`
