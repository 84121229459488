// extracted by mini-css-extract-plugin
export var ButtonLink = "TopMenu-module--ButtonLink--58cba";
export var CenterWrap = "TopMenu-module--CenterWrap--3674b";
export var Container = "TopMenu-module--Container--f25e1";
export var FlexWrap = "TopMenu-module--FlexWrap--acbfe";
export var GridCenter = "TopMenu-module--GridCenter--c49ee";
export var btn = "TopMenu-module--btn--e57b3";
export var btn_black = "TopMenu-module--btn_black--4cbb1";
export var btn_purple = "TopMenu-module--btn_purple--d7c4e";
export var header = "TopMenu-module--header--73550";
export var skip_button = "TopMenu-module--skip_button--1bf34";