import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './Map.module.scss';
import MarkerPin from '../images/marker.png'
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  KmlLayer,
  Marker
} from "@react-google-maps/api";
const lib = ["places"];
const idG = ["614f2b924c037cf7"];
const key = "AIzaSyAS-DIcFXcADccoiSNoM533jtzGa06hJzI"; // PUT GMAP API KEY HERE
const defaultLocation = { lat: 60.219386, lng: -101.645961 };



export const Map = ({ slice, context }) => {
  
  const mapMarkers = context.markerPins;
  const map_markers = mapMarkers?.markers || [];
  const [map, setMap] = React.useState(null);
  const [activeMarker, setActiveMarker] = React.useState(null);  
  const onLoad = React.useCallback((map) => setMap(map), []);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  React.useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      map_markers.map(marker => {
        bounds.extend({
          lat: marker.location?.latitude,
          lng: marker.location?.longitude,
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, map_markers]);
  
  const mapStyles = [
  {
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#5bd048"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#26aab7"
      }
    ]
  }
];
  return (
    <section style={{paddingBottom: 0}}>
      <Container>
        <div className={sty.CenterWrap}>
          <PrismicRichText field={slice.primary.rt?.richText}/>
        </div>
      </Container>
      <div className={sty.MapWrap}>
        <LoadScript googleMapsApiKey={key} libraries={lib} mapIds={idG}>
          <GoogleMap
            center={defaultLocation}
            zoom={3}
            onLoad={onLoad}
            onClick={() => setActiveMarker(null)}
            options={{ styles: mapStyles/*, mapId: "614f2b924c037cf7"*/}}
            mapContainerStyle={{ height: "600px"}}
            disableDefaultUI={true}
            fullscreenControl={false}

          >
            {map_markers?.map((item,index) => (
              <Marker 
                k key={index + 1}
                onClick={() => handleActiveMarker(index + 1)}
                position={{lat: item.location?.latitude, lng: item.location?.longitude}}
                icon={MarkerPin}
                
              >
                {activeMarker === (index + 1) ? (
                  <InfoWindow 
                    onCloseClick={() => setActiveMarker(null)}
                    className={sty.InfoWindow}
                  >
                    <div className={sty.FlexWrap}>
                      <div className={sty.ImageBox}>
                        <GatsbyImage
                          image={item.img?.gatsbyImageData}
                          alt=""
                          className={sty.image}
                        />
                      </div>
                      <div className={sty.CopyBox}>
                        <PrismicRichText field={item.info?.richText}/>
                      </div>
                    </div>
                  </InfoWindow>
                ) : null}

              </Marker>

            ))}

            
          </GoogleMap>
        </LoadScript>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMap on PrismicHomepageDataBodyMap {
    id
    primary {
      rt {
        richText
      }
    }
  }
`
