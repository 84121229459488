import React, { CSSProperties }  from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './QuoteCarousel.module.scss';
import { useSnapCarousel } from 'react-snap-carousel';

export const QuoteCarousel = ({ slice, context }) => {
  const { colour } = context
  const style = {
    backgroundColor: colour,
  }
  const { scrollRef, pages, activePageIndex, next, prev, goTo } =
  useSnapCarousel();
  return (

    <section className={sty.QuoteCarousel} style={style}>
      <Container>
        <ul
          ref={scrollRef}
          className={sty.scroll}
          style={{
            display: 'flex',
            overflow: 'auto',
            scrollSnapType: 'x mandatory',
            gap: '40px'
          }}
        >
          {slice.items.map((item, index) => (
            <li className={`${sty.GridTwo}`} key={`stat: ${index}`} 
            style={{
              width: '100%',
              height: '350px',
              flexShrink: 0,
              alignItems: 'center',
            }}
            >
              <div className={sty.fact}>
                <PrismicRichText field={item.key_fact?.richText}/>
              </div>
              <div className={sty.quote}>
                <blockquote>{item.quote}</blockquote>
                <span>{item.quote_author}</span>
              </div>
            </li>
          ))}
          </ul>
          <div className={sty.navContainer}>
            <div className={sty.numbers}>
              {activePageIndex + 1} / {pages.length}
            </div>
            <div>      <button onClick={() => prev()} aria-label="Prev" className={sty.prev}></button>
            <button onClick={() => next()} aria-label="next" className={sty.next}></button></div>
          </div>
      
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment ActionDataBodyQuoteCarousel on PrismicActionDataBodyQuoteCarousel {
    id
    items {
      quote_author
      quote
      key_fact {
        richText
      }
    }
  }
`
