exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-thank-you-fr-js": () => import("./../../../src/pages/thankYou-fr.js" /* webpackChunkName: "component---src-pages-thank-you-fr-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thankYou.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-action-js": () => import("./../../../src/templates/action.js" /* webpackChunkName: "component---src-templates-action-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

