import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './TextImage.module.scss';

export const TextImage = ({ slice }) => {
  const right = slice.primary.image_side
  const tags = slice.primary.image_tag?.split(" ") || [];
  const image_tags = tags.map((str) => (str.replace(/_/g, ' ')));

  const image = (
    <div className={sty.imageBox}>
      <GatsbyImage
        image={slice.primary.img?.gatsbyImageData}
        alt={slice.primary.img?.alt || ""}
        className={sty.image}
      />
      <p className={`${sty.imageTag} ${right? sty.right : sty.left}`}>
        {image_tags.map((item,index) => (
          <span key={`tag-part: ${index}`} className={`${sty.paper_word} ${sty["nth_"+index % 4]}`}>
            {item}
          </span>
        ))}
      </p>
    </div>
  );
  const text = (
    <div className={sty.copyBox}>
      <PrismicRichText field={slice.primary.rt?.richText}/>
    </div>
  );
  return (
    <section className={sty.TextImage}>
      <Container>
        <div className={`${sty.FlexWrap} ${right? sty.imageRight : ""}`}>
          {right? (<>{text} {image}</>) : (<>{image} {text}</>)}
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTextImage on PrismicHomepageDataBodyTextImage {
    id
    primary {
      image_side
      image_tag
      img{
        gatsbyImageData(width: 614)
        alt 
      }
      rt {
        richText 
      }
    }
  }
  fragment ActionDataBodyTextImage on PrismicActionDataBodyTextImage {
    id
    primary {
      image_side
      image_tag
      img{
        gatsbyImageData(width: 614)
        alt 
      }
      rt {
        richText 
      }
    }
  }
`
