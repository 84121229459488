import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './ActionsList.module.scss';

const ActionCard = (props) => {
  const {
    color, 
    page_link, 
    title,
    key, 
    image
  } = props

  const style = {
    background: color,
    boxShadow: `0px 4px 41px -6px ${color}`,
  }
  return (
    
    <PrismicLink href={page_link} key={key} className={sty.ActionCard}>
      <div style={style} className={sty.Background}>
        <GatsbyImage
          image={image?.gatsbyImageData}
          alt={image?.alt || ""}
          objectFit='contain'
          style={{
            width: '100%',
            height: '100%',
          }}
          imageStyle={{
            margin: 40
          }}
        />
      </div>
      {title?.text}
    </PrismicLink>
  )
}
export const ActionsList = ({ slice,context }) => {
  const actions = context?.actions || {}
  const header = slice.primary?.heading___description
  return (
    <section className={sty.ActionsList}>
      <Container>
        <div className={sty.Header}>
          <PrismicRichText field={header?.richText}/>
        </div>
        <div className={sty.ActionGrid}>
          {actions?.map(act => {
            if(act.id !== context.id) return(     
            <ActionCard
              title={act.data?.page_title}
              image={act.data?.action_graphic}
              page_link={act.url}
              color={act.data?.action_colour}
              key={act.id}
            />        
          )})}
        </div>
        
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment ActionDataBodyActionsList on PrismicActionDataBodyActionsList {
    id
    primary {
      heading___description {
        richText
      }
    }
  }
  fragment HomepageDataBodyActionsList on PrismicHomepageDataBodyActionsList {
    id
    primary {
      heading___description {
        richText
      }
    }
  }
`
