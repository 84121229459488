import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './NewsMedia.module.scss';
import { useSnapCarousel } from 'react-snap-carousel';

export const NewsMedia = ({ slice }) => {
  const { scrollRef, pages, activePageIndex, next, prev, goTo } =
  useSnapCarousel();
  return (
    <section className={sty.NewsMedia}>
      <Container>
        <PrismicRichText field={slice.primary.heading?.richText}/>
        <ul
          className={sty.scroll}
          ref={scrollRef}
          style={{
            display: 'flex',
            overflow: 'auto',
            scrollSnapType: 'x mandatory',
            gap:'40px'
          }}
        >
        {slice.items.map((item, index) => (
          <li className={sty.item} key={`stat: ${index}`}
          >
            <div className={sty.copy}>
            <PrismicRichText field={item.news_title?.richText}/>
            <p><span>{item.published_on}</span>
            <span>{item.author}</span>
            <span>{item.date}</span></p>
            <PrismicLink href={item.article_link?.url} className="btn_purple">
              Learn More
            </PrismicLink>
            </div>
          </li>
        ))}
        </ul>
        <div className={sty.navContainer}>
            <div className={sty.numbers}>
              {activePageIndex + 1} / {pages.length}
            </div>
            <div>      <button onClick={() => prev()} aria-label="Prev" className={sty.prev}></button>
            <button onClick={() => next()} aria-label="next" className={sty.next}></button></div>
          </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment ActionDataBodyNewsMedia on PrismicActionDataBodyNewsMedia {
    id
    primary {
      heading {
        richText
      }
    }
    items {
      article_link {
        url
        id
      }
      author
      date
      published_on
      news_title {
        richText
      }
    }
  }
`
