import { AlternatingStat } from "./AlternatingStat"
import {CTA} from "./CTA"
import {HomepageHero} from "./HomepageHero"
import {Leaders} from "./Leaders"
import {Map} from "./Map"
import {Partners} from "./Partners"
import {Socials} from "./Socials"
import {TextImage} from "./TextImage"
import {ContactForm} from "./ContactForm"
import { QuoteCarousel } from "./QuoteCarousel"
import { CentreAlignedColumnsWPaper } from "./CentreAlignedColumnsWPaper"
import { NewsMedia } from "./NewsMedia"
import { ActionsList } from "./ActionsList"

export const components = {
  alternating_stat: AlternatingStat,
  cta: CTA,
  homepage_hero: HomepageHero,
  leaders: Leaders,
  map: Map,
  partners: Partners,
  socials: Socials,
  text_image: TextImage,
  contact_form: ContactForm,
  centre_aligned_columns_w__paper: CentreAlignedColumnsWPaper,
  quote_carousel: QuoteCarousel,
  news___media: NewsMedia,
  actions_list: ActionsList,

}
