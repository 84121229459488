// extracted by mini-css-extract-plugin
export var CenterWrap = "BottomMenu-module--CenterWrap--99c33";
export var Container = "BottomMenu-module--Container--24fa6";
export var FlexCol = "BottomMenu-module--FlexCol--25901";
export var FlexWrap = "BottomMenu-module--FlexWrap--ac353";
export var Footer = "BottomMenu-module--Footer--2de26";
export var Form = "BottomMenu-module--Form--d38f9";
export var GridCenter = "BottomMenu-module--GridCenter--f14e5";
export var btn = "BottomMenu-module--btn--5133a";
export var btn_black = "BottomMenu-module--btn_black--b34e8";
export var btn_purple = "BottomMenu-module--btn_purple--03c55";
export var flexForm = "BottomMenu-module--flexForm--e6eca";
export var socialLink = "BottomMenu-module--socialLink--ff91c";