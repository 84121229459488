import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './CentreAlignedColumnsWPaper.module.scss';

export const CentreAlignedColumnsWPaper = ({ slice }) => {
  const right = slice.primary.image_side
  const image = (
    <div className={sty.imageBox}>
      <GatsbyImage
        image={slice.primary.img?.gatsbyImageData}
        alt={slice.primary.img?.alt || ""}
        className={sty.image}
      />
    </div>
  );
  const paperify = (str) => {
    const str_list = str.split(" ");
    return (
      <>
      {str_list.map((item,index) => (
        <span key={`paper: ${index}`} className={`${sty.paper_word} ${sty["nth_"+index % 2]}`}>{item}</span>
      ))}
      </>
    );
  };
  const text = (
    <div className={sty.copyBox}>
      <PrismicRichText field={slice.primary.heading___description?.richText}
        components={{
          heading2: ({ children,text }) => <h2>{paperify(text)}</h2>
        }}
      />
    </div>
  );
  return (
    <section className={sty.CentreAlignedColumnsWPaper}>
      <Container>
        <PrismicRichText field={slice.primary.section_heading?.richText}/>
        <div className={sty.GridCenter}>
        {slice.items.map((item, index) => (
          <div className={sty.item} key={`stat: ${index}`}>
            <div className={sty.imageBox}>
              <GatsbyImage
                image={item.image?.gatsbyImageData}
                alt={item.image?.alt}
                className={sty.image}
              />
            </div>
            <div className={sty.copy}>
              <PrismicRichText field={item.copy?.richText}/>
            </div>
          </div>
        ))}
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment ActionDataBodyCentreAlignedColumnsWPaper on PrismicActionDataBodyCentreAlignedColumnsWPaper {
    id
    items {
      image {
        alt
        gatsbyImageData
      }
      copy {
        richText
      }
    }
    primary {
      button_text
      call_to_action_link {
        url
        id
      }
      section_heading {
        richText
      }
    }
  }
`
