import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './Partners.module.scss';

export const Partners = ({ slice }) => {
  return (
    <section className={sty.Partners}>
      <Container>
        <div className={`${sty.FlexWrap} ${sty.BlurBox}`}>
          <div className={`${sty.imageBox} ${sty.lightBox}`}>
            <GatsbyImage
              image={slice.primary.img?.gatsbyImageData}
              alt={slice.primary.img?.alt || ""}
              className={sty.image}
            />
          </div>
          <div className={sty.copyBox}>
            <PrismicRichText field={slice.primary.top_rt?.richText}/>
          </div>
        </div>
        <div className={sty.FlexWrap}>
          <div className={sty.copyBox}>
            <PrismicRichText field={slice.primary.bottom_rt?.richText}/>
          </div>
          <div className={sty.imageBox}>
            {slice.items.map((item,index) => (
              <PrismicLink 
                className={sty.imageWrap} 
                href={item.link?.url}
                key={`partner-${index}`}
              >
                <GatsbyImage
                  image={item.img?.gatsbyImageData}
                  alt={item.img?.alt || ""}
                  className={sty.image}
                />
              </PrismicLink>
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPartners on PrismicHomepageDataBodyPartners {
    id
    primary {
      top_rt {
        richText
      }
      bottom_rt {
        richText
      }
      img {
        gatsbyImageData
        alt
      }
    }
    items {
      img {
        gatsbyImageData
        alt
      }
      link {
        url
      }
    }
  }
`
