import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import { FaShareAlt,FaLink } from "react-icons/fa"

import * as sty from './Socials.module.scss';

export const Socials = ({ slice }) => {
  const [copied, setCopied] = React.useState(false);

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  return (
    <section className={sty.Socials}>
      <Container>
        <div className={sty.FlexWrap}>
          <div className={sty.copyBox}>
            <PrismicRichText field={slice.primary.rt?.richText}
              components={{
                heading2: ({ children }) => <h2>{children}<FaShareAlt/></h2>
              }}
            />
          </div>
          <div className={sty.socialBox}>
            <button onClick={copy} className={sty.linkCopy}><FaLink/>{!copied ? slice.primary.web : slice.primary.copied_text}</button>
          </div>
          <div className={sty.hashTag}>
            <span>{slice.primary.hashtag}</span>
          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodySocials on PrismicHomepageDataBodySocials {
    id
    primary {
      rt {
        richText
      }
      copied_text
      web
      insta
      tiktok
      twitter
      hashtag
    }
  }
  fragment ActionDataBodySocials on PrismicActionDataBodySocials {
    id
    primary {
      rt {
        richText
      }
      copied_text
      web
      insta
      tiktok
      twitter
      hashtag
    }
  }
`
