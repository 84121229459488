import * as React from 'react'
import { useState } from 'react'
import { graphql } from 'gatsby'
import { Container } from '../components/Container'
import * as sty from './HomepageHero.module.scss'
import { linkToApp } from './utils'

export const HomepageHero = ({ slice }) => {
  const [email, setEmail] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    window.location.assign(linkToApp(email))
  }
  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <section className={sty.HomepageHero}>
      <Container>
        <div className={sty.CenterWrap}>
          <h1 className={sty.title}>{slice.primary.title}</h1>
          <div className={sty.copy}>
            <p>{slice.primary.description}</p>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" className={sty.hidden}>
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                placeholder={slice.primary.placeholder}
              />
            </form>
            <button className={sty.submit} onClick={handleSubmit}>
              Get Started
            </button>
          </div>
        </div>
      </Container>
      <div className={sty.bgImg1} />
      <div className={sty.bgImg2} />
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHomepageHero on PrismicHomepageDataBodyHomepageHero {
    id
    primary {
      title
      description
      placeholder
      btn_label
    }
  }
`
