// extracted by mini-css-extract-plugin
export var CTA = "CTA-module--CTA--59ede";
export var CenterWrap = "CTA-module--CenterWrap--dd131";
export var Container = "CTA-module--Container--c867d";
export var FlexWrap = "CTA-module--FlexWrap--9c604";
export var GridCenter = "CTA-module--GridCenter--821fd";
export var btn = "CTA-module--btn--aef0f";
export var btn_black = "CTA-module--btn_black--04645";
export var btn_purple = "CTA-module--btn_purple--5635e";
export var copyBox = "CTA-module--copyBox--e0961";
export var imageBox = "CTA-module--imageBox--95376";
export var left = "CTA-module--left--717c7";
export var nth_0 = "CTA-module--nth_0--d1e6d";
export var nth_1 = "CTA-module--nth_1--0a6ff";
export var paper_word = "CTA-module--paper_word--aebbf";
export var right = "CTA-module--right--a870d";