// extracted by mini-css-extract-plugin
export var CenterWrap = "QuoteCarousel-module--CenterWrap--8a925";
export var Container = "QuoteCarousel-module--Container--e04e7";
export var FlexWrap = "QuoteCarousel-module--FlexWrap--3548a";
export var GridCenter = "QuoteCarousel-module--GridCenter--4b830";
export var GridTwo = "QuoteCarousel-module--GridTwo--e1730";
export var QuoteCarousel = "QuoteCarousel-module--QuoteCarousel--709b5";
export var btn = "QuoteCarousel-module--btn--815f9";
export var btn_black = "QuoteCarousel-module--btn_black--0e6c2";
export var btn_purple = "QuoteCarousel-module--btn_purple--cf552";
export var fact = "QuoteCarousel-module--fact--ff020";
export var navContainer = "QuoteCarousel-module--navContainer--e6a5a";
export var next = "QuoteCarousel-module--next--862fd";
export var numbers = "QuoteCarousel-module--numbers--5bdc7";
export var prev = "QuoteCarousel-module--prev--db7c2";
export var quote = "QuoteCarousel-module--quote--51752";
export var scroll = "QuoteCarousel-module--scroll--420a4";