import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import * as sty from './Homepage.module.scss'
import { Layout } from '../components/Layout'
import { components } from '../slices'

const HomepageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const menu = data.prismicMenu || {}
  const markers = data.prismicMapMarkers || {}
  const allActions = data.allPrismicAction || {}
  const actions = allActions?.nodes || {}
  
  const { lang, type, url, id } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const targetRef = React.useRef();
  const [bgHeight, setBgHeight] = React.useState(null);
  React.useEffect(() => {
    function handleResize() {
      setBgHeight(targetRef.current?.offsetTop + 5);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const style = {
    height: bgHeight!= null ? bgHeight : targetRef.current?.offsetTop ? targetRef.current?.offsetTop + 5 : 5000
  }
  return (
    <Layout menu={menu.data} activeDocMeta={activeDoc} >
      <div className={sty.Homepage}>
        <div className={sty.HomepageBg} style={style}/>
        <SliceZone slices={homepage.data?.body} components={components} context={{ref: targetRef, markerPins: markers.data,lang: lang, actions: actions, id: id}}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      id
      type
       data {
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
        
          ...HomepageDataBodyAlternatingStat
          ...HomepageDataBodyCta
          ...HomepageDataBodyHomepageHero
          ...HomepageDataBodyLeaders
          ...HomepageDataBodyMap
          ...HomepageDataBodyPartners
          ...HomepageDataBodySocials
          ...HomepageDataBodyTextImage
          ...HomepageDataBodyActionsList

        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
    allPrismicAction(filter: {lang: { eq: $lang}}) {
      nodes {
        url
        id
        data {
          page_title {
            richText
            text
          }
          action_colour
          action_graphic {
            alt
            url
            gatsbyImageData(
              layout: CONSTRAINED
            )
          }
        }
      }
      
    }
    prismicMapMarkers(lang: { eq: $lang }) {
      data {
        marker_pin {
          url
        }
        markers {
          img {
            gatsbyImageData
            alt
          }
          info {
            richText
            text
          }
          location {
            latitude
            longitude
          }
        }
      }
    }
  }
`

export default withPrismicPreview(HomepageTemplate)
