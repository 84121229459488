import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './ContactForm.module.scss';
import { navigate } from 'gatsby-link'

export const ContactForm = ({ slice, context }) => {
  React.useEffect(() => {
    document.body.classList.add('contact')
  }, []);
  const thank_you = context.lang.slice(0,2) == "fr" ? "/thankYou-fr/" : "/thankYou/";

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const [state, setState] = React.useState({})
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/en/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <section className={sty.contactForm}>
      <Container>
        <div className={sty.wrapper}>
          <form 
            method="POST" 
            netlify-honeypot="bot-field" 
            data-netlify="true" 
            name="contact"
            onSubmit={handleSubmit}
            action={thank_you}
          >
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <input type="hidden" name="form-name" value="contact" onChange={handleChange}/>
            <label htmlFor="name">{slice.primary.name_label}</label>
            <input id="name" type="text" name="name" required onChange={handleChange}/>
            <label htmlFor="email">{slice.primary.email_label}</label>
            <input id="email" type="email" name="email" required onChange={handleChange}/>
            <label htmlFor="message">{slice.primary.message_label}</label>
            <textarea id="message" name="message" type="text" rows="8" onChange={handleChange}/>
            <input type="submit" value={slice.primary.submit_placeholder} className={sty.btn_black} onChange={handleChange}/>
          </form>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyContactForm on PrismicPageDataBodyContactForm {
    id
    primary {
      name_label
      email_label
      message_label
    }
  }
`
