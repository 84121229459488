// extracted by mini-css-extract-plugin
export var CenterWrap = "HomepageHero-module--CenterWrap--28b28";
export var Container = "HomepageHero-module--Container--3bac9";
export var FlexWrap = "HomepageHero-module--FlexWrap--da7fe";
export var GridCenter = "HomepageHero-module--GridCenter--ec954";
export var HomepageHero = "HomepageHero-module--HomepageHero--f77b5";
export var bgImg1 = "HomepageHero-module--bgImg1--b46fe";
export var bgImg2 = "HomepageHero-module--bgImg2--650f8";
export var btn = "HomepageHero-module--btn--8aa43";
export var btn_black = "HomepageHero-module--btn_black--1f251";
export var btn_purple = "HomepageHero-module--btn_purple--904b3";
export var copy = "HomepageHero-module--copy--76391";
export var desc = "HomepageHero-module--desc--bba03";
export var hidden = "HomepageHero-module--hidden--015f7";
export var submit = "HomepageHero-module--submit--e2019";
export var title = "HomepageHero-module--title--e27fa";