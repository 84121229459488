// extracted by mini-css-extract-plugin
export var CenterWrap = "Subpages-module--CenterWrap--7de68";
export var Container = "Subpages-module--Container--37b8b";
export var FlexWrap = "Subpages-module--FlexWrap--d0e84";
export var GridCenter = "Subpages-module--GridCenter--3a205";
export var btn = "Subpages-module--btn--91065";
export var btn_black = "Subpages-module--btn_black--e34b5";
export var btn_purple = "Subpages-module--btn_purple--29d31";
export var subDescription = "Subpages-module--subDescription--3a01f";
export var subHeading = "Subpages-module--subHeading--7432f";
export var subPages = "Subpages-module--subPages--1f6c1";