// extracted by mini-css-extract-plugin
export var CenterWrap = "Socials-module--CenterWrap--77100";
export var Container = "Socials-module--Container--00a49";
export var FlexWrap = "Socials-module--FlexWrap--cc9c1";
export var GridCenter = "Socials-module--GridCenter--3f285";
export var Socials = "Socials-module--Socials--065c4";
export var btn = "Socials-module--btn--2c8fa";
export var btn_black = "Socials-module--btn_black--2bc53";
export var btn_purple = "Socials-module--btn_purple--64ff6";
export var copyBox = "Socials-module--copyBox--4cb94";
export var hashTag = "Socials-module--hashTag--bf799";
export var linkCopy = "Socials-module--linkCopy--036b8";
export var socialBox = "Socials-module--socialBox--ecffe";