// extracted by mini-css-extract-plugin
export var CenterWrap = "Leaders-module--CenterWrap--50166";
export var Container = "Leaders-module--Container--3f31c";
export var FlexWrap = "Leaders-module--FlexWrap--3e04c";
export var GridCenter = "Leaders-module--GridCenter--38d1f";
export var Leaders = "Leaders-module--Leaders--38ad4";
export var active = "Leaders-module--active--93c57";
export var btn = "Leaders-module--btn--fd527";
export var btn_black = "Leaders-module--btn_black--1ed60";
export var btn_purple = "Leaders-module--btn_purple--f3857";
export var copyBox = "Leaders-module--copyBox--be5c1";
export var image = "Leaders-module--image--d2100";
export var inactive = "Leaders-module--inactive--6fcb2";
export var leaderBox = "Leaders-module--leaderBox--f004e";
export var leaderIndex = "Leaders-module--leaderIndex--fda57";
export var leaderInfo = "Leaders-module--leaderInfo--cd869";