import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { Container } from './Container'
import * as sty from './BottomMenu.module.scss'
import { FaEnvelope, FaInstagram, FaTiktok, FaTwitter } from 'react-icons/fa'
import { linkToApp } from '../slices/utils'

export const BottomMenu = ({ menu }) => {
  const [email, setEmail] = React.useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    window.location.assign(linkToApp(email))
  }
  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <footer className={sty.Footer}>
      <Container>
        <div className={sty.FlexWrap}>
          <div className={sty.FlexCol}>
            <PrismicLink href={menu.homepage?.url}>
              <GatsbyImage
                image={menu.footer_logo?.gatsbyImageData}
                alt={menu.footer_logo?.alt || ''}
                className={sty.logo}
              />
            </PrismicLink>
            <a href={`mailto:${menu.email}`} className={sty.socialLink}>
              <FaEnvelope />
              {menu.email}
            </a>
            <PrismicLink href={menu.contact_btn?.url}>
              {menu.contact_label}
            </PrismicLink>
            <PrismicLink
              href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
              target="_blank"
            >
              <StaticImage
                src="../images/cc.png"
                alt="Creative Commons License"
                className={sty.cc}
                width={88}
              />
            </PrismicLink>
          </div>
          <div className={sty.FlexCol}>
            <p className={sty.colHeader}>
              <b>{menu.socials_header}</b>
            </p>
            {menu.tiktok && (
              <PrismicLink
                aria-label="tiktok"
                href={`https://www.tiktok.com/${menu.tiktok}`}
                className={sty.socialLink}
              >
                <FaTiktok />
                {menu.tiktok}
              </PrismicLink>
            )}
            {menu.instagram && (
              <PrismicLink
                aria-label="Instagram"
                href={`https://www.instagram.com/${menu.instagram}`}
                className={sty.socialLink}
              >
                <FaInstagram />
                {menu.instagram}
              </PrismicLink>
            )}
            {menu.twitter && (
              <PrismicLink
                aria-label="Twitter"
                href={`https://twitter.com/${menu.twitter}`}
                className={sty.socialLink}
              >
                <FaTwitter />
                {menu.twitter}
              </PrismicLink>
            )}
          </div>
          <div className={sty.Form}>
            <form onSubmit={handleSubmit}>
              <label htmlFor="emailFooter" className={sty.colHeader}>
                {menu.form_header}
              </label>
              <div className={sty.flexForm}>
                <input
                  type="email"
                  id="emailFooter"
                  name="email"
                  placeholder={menu.email_label}
                  onChange={handleChange}
                />
                <input
                  type="submit"
                  value={menu.submit_label || 'Submit'}
                  className={sty.btn_purple}
                />
              </div>
            </form>
            <PrismicRichText
              field={menu.form_info?.richText}
              className={sty.formInfo}
            />
          </div>
        </div>
      </Container>
    </footer>
  )
}

export const query = graphql`
  fragment BottomMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      email
      email_label
      contact_btn {
        url
      }
      contact_label
      form_header
      instagram
      form_info {
        richText
      }
      homepage {
        url
      }
      footer_logo {
        gatsbyImageData(width: 250)
        alt
      }
      socials_header
      tiktok
      submit_label
      twitter
    }
  }
`
