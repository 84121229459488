import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as sty from './CTA.module.scss';

export const CTA = ({ slice }) => {
  const right = slice.primary.image_side
  const image = (
    <div className={sty.imageBox}>
      <GatsbyImage
        image={slice.primary.img?.gatsbyImageData}
        alt={slice.primary.img?.alt || ""}
        className={sty.image}
      />
    </div>
  );
  const paperify = (str) => {
    const str_list = str.split(" ");
    return (
      <>
      {str_list.map((item,index) => (
        <span key={`paper: ${index}`} className={`${sty.paper_word} ${sty["nth_"+index % 2]}`}>{item}</span>
      ))}
      </>
    );
  };
  const text = (
    <div className={sty.copyBox}>
      <PrismicRichText field={slice.primary.rt?.richText}
        components={{
          heading2: ({ children,text }) => <h2>{paperify(text)}</h2>
        }}
      />
    </div>
  );
  return (
    <section className={sty.CTA} style={{background:slice.primary.bg_color}}>
      <Container>
        <div className={`${sty.FlexWrap} ${right ? sty.right : sty.left}`}>
          {right? (<>{text} {image}</>) : (<>{image} {text}</>)}
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCta on PrismicHomepageDataBodyCta {
    id
    primary {
      bg_color
      image_side
      rt {
        richText
      }
      img {
        gatsbyImageData
        alt 
      }
    }
  }
`
